
import homeRowItem from '@/components/homeRowItem';
import concactDialog from './map/components/concact-dialog.vue'
export default {
  name : 'Index',
  layout : 'base',
  components: {
    homeRowItem,
    concactDialog
  },
  head() {
    return {
      title : this.headTitle,
      meta : [
        { hid : 'keywords', name : 'keywords', content : this.headKeywords },
        { hid : 'description', name : 'description', content : this.headDesc }
      ]
    }
  },
  data() {
    return {
      headKeywords: '',
      headDesc: '',
      banners: [{
      }],
      infoList: [
        {
          img: require('@/assets/p1.png'),
          title: '企业快查',
          url: '/enterprise',
          desc: '实时搜索全国2亿＋企业千余维度数据，实时了解企业360度全景信息。'
        },
        {
          img: require('@/assets/p2.png'),
          title: '产业地图',
          url: '/map',
          desc: '依托深圳市GIS三维地图，深度融合产业发展要素，动态实时呈现城市、园区行业分布、产业链分布、企业分布及发展状况。'
        },
        {
          img: require('@/assets/p3.png'),
          title: '精准招商',
          url: '/map',
          desc: '基于深圳市城市更新拟拆迁项目，一键触达刚需搬迁企业，精准获取招商靶向企业；基于园区换租企业，数据智能生成招商目标清单。为园区提供精准、实时的线上招商服务。'
        },
        {
          img: require('@/assets/p4.png'),
          title: '产业服务',
          url: '/service',
          desc: '面向企业实际需求，从找政策、找资金、找园区、找空间、找业务五大维度提供大数据服务，全面帮助企业快速发展。'
        },
        {
          img: require('@/assets/p5.png'),
          title: '产业研究',
          url: '/findings',
          desc: '从宏观经济，区域产业画像，行业发展研报，提供全类产业信息，协助企业实施了解宏观趋势、中观产业动向、微观行业发展信息，助力企业高效决策。'
        }
      ],
      clomnList: [
        {
          title: '产业咨询服务',
          desc: '立足产业大数据，洞察产业发展逻辑；提供产业拿地、产业研究产业规划、产业定位等咨询服务',
          img: require('@/assets/wa-line1.png')
        },
        {
          title: '园区数字化服务',
          desc: '凭借数字技术，对园区内人流 、物流 、能耗、环保、消防和安防等系统 应用云计算 、物联网等 新技术 ，规划支撑平台，提供数字化解决方案 ',
          img: require('@/assets/wa-line2.png')
        },
        {
          title: '工业上楼服务',
          desc: '结合产业发展趋势，从项目拿地研判，产业定位、规划、设计、产业导入、产业数智运营提供全周期产业服务',
          img: require('@/assets/wa-line3.png')
        },
        {
          title: '企业数字转型服务',
          desc: '提供企业现状诊断、数字化转型顶层规划设计，全方位全流程提供敏捷性解决方案，为企业降本增效',
          img: require('@/assets/wa-line4.png')
        },
        {
          title: '数字招商服务',
          desc: '依托海量产业大数据，利用云计算、人工智能等新一代技术，为客户提供精准的招商线索及招商工具',
          img: require('@/assets/wa-line5.png')
        }
      ],
      bottList: [
        {
          title: '企业数据',
          data: '2亿 +',
          imageSrc: require('@/assets/s1.png'),
        },
        {
          title: '行业数据',
          data: '1000万 + ',
          imageSrc: require('@/assets/s2.png'),
        },
        {
          title: '企服数据',
          data: '10万 + ',
          imageSrc: require('@/assets/s3.png'),
        },
        {
          title: '产业链数据',
          data: '100 + ',
          imageSrc: require('@/assets/s4.png'),
        },
        {
          title: '园区数据',
          data: '10000 + ',
          imageSrc: require('@/assets/s5.png'),
        },
        {
          title: '人才数据',
          data: '10万 + ',
          imageSrc: require('@/assets/s6.png'),
        },
        {
          title: '舆情数据',
          data: '10万 + ',
          imageSrc: require('@/assets/s7.png'),
        },
        {
          title: '地图数据',
          data: '100 + ',
          imageSrc: require('@/assets/s8.png'),
        },
        {
          title: '金融数据',
          data: '1万 + ',
          imageSrc: require('@/assets/s9.png'),
        },
        {
          title: '宏观经济数据',
          data: '1万 + ',
          imageSrc: require('@/assets/s10.png'),
        },
        {
          title: '产品数据',
          data: '100万 + ',
          imageSrc: require('@/assets/s11.png'),
        },
        {
          title: '创投数据',
          data: '10万 + ',
          imageSrc: require('@/assets/s12.png'),
        },
        {
          title: '招投标数据',
          data: '10万 + ',
          imageSrc: require('@/assets/s13.png'),
        },
        {
          title: '进出口数据',
          data: '100万 + ',
          imageSrc: require('@/assets/s14.png'),
        }
      ],
    }
  },
  computed: {
    
  },
  mounted() {
    // this.wowInit()
  },
  methods: {
    toRoute(url) {
      this.$router.push({
        path: url
      })
    },
    concactUs() {
      this.$refs.concactDialogPop.showPop()
    },
    wowInit() {
    //   this.$nextTick( () => {        
    //     var wow = new WOW({
    //       boxClass: "wow", ///动画元件css类（默认为wow）
    //       animateClass: "animated", //动画css类（默认为animated）
    //       offset: 0, //到元素距离触发动画（当默认为0）
    //       mobile: true, //在移动设备上触发动画（默认为true）
    //       live: true, //对异步加载的内容进行操作（默认为true）
    //     });
    //     wow.init();      
    // })
    }
  },

}
