
import { onSubmitContactInfo } from '@/api/user.js'
export default {
  data() {
    return {
      modelForm: {}, 
      loading: false, 
      dialogVisible: false
    }
  },
  methods: {
    async showPop() {
        this.dialogVisible = true      
    },
    hidePop() {
        this.dialogVisible = false
    },      
    async handleSuccess() { 
        this.loading = true     
        const res = await onSubmitContactInfo(this.modelForm)
        this.hidePop()
        if (res) {
          this.$toasted.success('提交成功，我们将尽快联系您',{position: "top-center", duration: 500})
        }
        
        this.loading = false
        
    }
  }
}
